<template>
  <b-container fluid>
    <b-row>
      <LayoutHeader />
      <b-col sm="12">
        <b-row>
          <b-col lg="3" class="profile-left">
            <ServicesCard />
            <FeatureCard />
          </b-col>
          <b-col lg="6" class="profile-center">
            <b-row>
              <b-col md="6" class="mb-2" lg="4" v-for="(stats,index) in hotelState" :key="index">
                      <iq-card>
                        <template v-slot:body>
                          <div class="d-flex rounded align-items-center mb-3 mb-lg-0">
                                <div class="rounded-circle iq-card-icon iq-bg-primary  dark-icon-light mr-3 d-flex align-items-center justify-content-center" style="width: 50px;height: 50px;line-height: 0;font-size: 20px;"> <i :class="stats.icon"></i></div>
                                <div class="text-left">
                                <h6>{{$t(stats.title)}}</h6>
                                <p class="mb-0 text-primary" v-if="hotel[stats.key]">{{$t('yes')}}</p>
                                <p class="mb-0 text-danger" v-else>{{$t('no')}}</p>
                                </div>
                            </div>
                        </template>
                        </iq-card>
                    </b-col>
            </b-row>
          </b-col>
          <b-col lg="3" class="profile-right">
            <MapCard />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import LayoutHeader from '@/components/hotelsComponents/LayoutHeader.vue'
import ServicesCard from '@/components/hotelsComponents/ServicesCard.vue'
import FeatureCard from '@/components/hotelsComponents/FeatureCard.vue'
import MapCard from '@/components/hotelsComponents/MapCard.vue'
export default {
  components: {
    LayoutHeader,
    ServicesCard,
    FeatureCard,
    MapCard
  },
  data () {
    return {
      imgPreview: 'test',
      payload: {
        title: '',
        stars: '',
        image: ''
      },
      hotelState: [
        {
          title: 'has-pool',
          icon: 'fas fa-swimming-pool',
          key: 'has_pool'
        },
        {
          title: 'sweet-room',
          icon: 'fas fa-sink',
          key: 'has_sweet_rooms'
        },
        {
          title: 'room-service',
          icon: 'fas fa-concierge-bell',
          key: 'has_room_service'
        },
        {
          title: 'restaurant',
          icon: 'fas fa-utensils',
          key: 'has_restaurant'
        },
        {
          title: 'gym',
          icon: 'fas fa-dumbbell',
          key: 'has_gym'
        },
        {
          title: 'free-parking',
          icon: 'fas fa-parking',
          key: 'has_free_parking'
        },
        {
          title: 'breakfast',
          icon: 'fas fa-coffee',
          key: 'has_brakfast'
        },
        {
          title: 'bar',
          icon: 'fas fa-glass-martini-alt',
          key: 'has_bar'
        }
      ]
    }
  },
  methods: {
    getImg (event) {
      this.imgPreview = URL.createObjectURL(event.target.files[0])
      this.payload.image = event.target.files[0]
    },
    submit () {
      this.updateHotels(this.returnData(this.payload)).then(() => {
        this.getHotel().then(() => {})
      })
    }
  },
  mounted () {
    this.getHotel().then(() => {
      this.payload.title = this.hotel.title
      this.payload.start_price = this.hotel.start_price
      this.payload.end_price = this.hotel.end_price
      this.payload.stars = this.hotel.stars
      this.imgPreview = this.hotel.image
    })
    // this.getCities()
  }
}
</script>
<style>

.img-preview {
    min-height: 300px;
    border: 2px dashed #ccc;
}
.img-preview img {
    object-fit: contain;
}

</style>
